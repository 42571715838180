// extracted by mini-css-extract-plugin
export var menu = "styles-module--menu--1dZ7Y";
export var menuDrawer = "styles-module--menuDrawer--320Vk";
export var active = "styles-module--active--1TG-7";
export var subMenu = "styles-module--subMenu--r-D0Q";
export var menuContainer = "styles-module--menuContainer--29rtp";
export var menuBgImage = "styles-module--menuBgImage--2e7mp";
export var threeCol = "styles-module--threeCol--Jj4xJ";
export var menuAddress = "styles-module--menuAddress--2S3wL";
export var menuContent = "styles-module--menuContent--qF5A-";
export var instagramLink = "styles-module--instagramLink---UOzv";
export var menuLogo = "styles-module--menuLogo--3_Duz";
export var logo = "styles-module--logo--hnITE";
export var BookNowButtonRow = "styles-module--BookNowButtonRow--2PY4o";
export var BookNowButton = "styles-module--BookNowButton--2x3cs";
export var BookNowButtonMobile = "styles-module--BookNowButtonMobile--XoSJn";
export var languageButton = "styles-module--languageButton--1qXqx";