import React from "react"
import * as styles from "./styles.module.css"
import { Link } from "gatsby"
import MenuBg from "../../images/menu-new-bg.png"
import InstagramIcon from "../../images/instagram-icon.png"
import AppLogo from "../../images/logo_main_white.png"
import { Button } from "@mui/material"
import { navigate } from "gatsby"
import { style } from "@mui/system"

const Navbar = () => {
  const [toggle, setToggle] = React.useState(false)
  const toggleMenu = value => {
    if (document) {
      const menuDrawer = document.querySelector(`.${styles.menuDrawer}`)
      const menu = document.querySelector(`.${styles.menu}`)
      const BookNowButtonRow = document.querySelector(
        `.${styles.BookNowButtonRow}`
      )
      if (!value) {
        menuDrawer.classList.remove(styles.active)
        menu.classList.remove(styles.active)
        BookNowButtonRow.classList.remove(styles.active)
      } else {
        menuDrawer.classList.add(styles.active)
        menu.classList.add(styles.active)
        BookNowButtonRow.classList.add(styles.active)
      }
      setToggle(value)
    }
  }

  return (
    <nav>
      <div className={styles.BookNowButtonRow}>
        <Button
          className={styles.BookNowButton}
          color="primary"
          variant="contained"
          onClick={() => navigate("/contact/")}
        >
          Buchen
        </Button>
      </div>
      <div
        className={styles.menu}
        onClick={() => {
          toggleMenu(!toggle)
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={styles.menuDrawer}>
        <div className={styles.languageButton}>
          <Link to="#" activeStyle={{ color: "#FFFFFF" }}>
            EN
          </Link>
          <Link to="#" activeStyle={{ color: "#FFFFFF" }}>
            GER
          </Link>
        </div>
        <div className={styles.menuContainer}>
          <div className={styles.menuContent}>
            <ul>
              <li>
                <Link
                  to="/"
                  activeStyle={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/about/"
                  activeStyle={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Die Villa
                </Link>
              </li>
              <li>
                <Link
                  to="/rooms/"
                  activeStyle={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Ebenen
                </Link>
                <ul className={styles.subMenu}>
                  <li>
                    <Link
                      to="/rooms/floor3/"
                      activeStyle={{ color: "#FFFFFF", fontWeight: "bold" }}
                    >
                      Ebenen3
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/rooms/floor2/"
                      activeStyle={{ color: "#FFFFFF", fontWeight: "bold" }}
                    >
                      Ebenen2
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/rooms/floor1/"
                      activeStyle={{ color: "#FFFFFF", fontWeight: "bold" }}
                    >
                      Ebenen1
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  to="/contact/"
                  activeStyle={{ color: "#FFFFFF", fontWeight: "bold" }}
                >
                  Kontakt
                </Link>
              </li>
            </ul>
            <Button
              className={styles.BookNowButtonMobile}
              color="primary"
              variant="contained"
              onClick={() => navigate("/contact/")}
            >
              Buchen
            </Button>
          </div>
          <div className={styles.menuBgImage}>
            <img src={MenuBg} alt="MenuBg" />
          </div>
          <div className={styles.threeCol}>
            <Link
              className={styles.instagramLink}
              to="https://www.instagram.com/six7house/"
              target="_blank"
            >
              <img src={InstagramIcon} alt="location Icon" />
              Follow us!
            </Link>
            <div className={styles.menuAddress}>
              <Link className={styles.menuLogo} to="/">
                <img src={AppLogo} alt="logo" className={styles.logo}></img>
                {/* <AppLogo /> */}
              </Link>
              <Link
                to="https://goo.gl/maps/J2oNoYtcJZ4XULkU9"
                target="_blank"
                className={styles.address}
              >
                15 Military Rd, Hout Bay, Cape Town, 7806, South Africa
                <br />
              </Link>
              <a href="mailto:katrin.heiss@six7house.com" target="_blank">
                katrin.heiss@six7house.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
