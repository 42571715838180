import * as React from "react"
import { Link } from "gatsby"
import { Box, styled } from "@mui/system"
// import AppLogo from "../../images/svg/logo.svg"
import MainLogo from "../../images/logo_main_white.png"
import Navbar from "./navbar.tsx"
const Logo = styled("div")({
  position: "absolute",
  left: 0,
  right: 0,
  textAlign: "center",
  padding: "24px",
  zIndex: 9,
})

const Header = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        ".logoLinkWrapper": {
          "@media screen and (max-width: 767px)": {
            position: "absolute",
            left: "24px",
          },
        },
        ".MainLogoHeader": {
          width: "76px",
          height: "auto",
        },
      }}
    >
      <Logo>
        <Link to="/" className="logoLinkWrapper">
          {/* <AppLogo /> */}
          <img className="MainLogoHeader" src={MainLogo} alt="Logo" />
        </Link>
        <Navbar />
      </Logo>
    </Box>
  )
}

export default Header
